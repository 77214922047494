<template>
<div class="wrapper_main">
	<main class="content">
		<div class="content__header">
			<div class="content__header-left">
				<h1 class="heading-page-h1">{{ $translate('teams') }}</h1>
			</div>
			<div class="content__header-right tutorial_tooltip_left_center">
				<BluePlusButton to="/teams/add" v-if="userData.id && !(maxTeamMembers <= filteredRows.length)">{{ $translate('add-user') }}</BluePlusButton>
			</div>
		</div>
		<div style="min-height: 250px;" :class="{ 'processing':loading }">
			<TableAdvanced 
				class="action_text_align_right"
				v-model="sortCol" 
				:rows="filteredRows" 
				:columns="columns" 
				@resetFilters="resetFilters" 
				:isCheckedAll="isCheckedAll"
				:checkedInputs="checkedInputs"
				@toggleAllCheckedInputs="toggleAllCheckedInputs"
			>
				<template v-slot:filters>
					<div class="filter-options__search filter-options__search--md">
						<input type="search" class="input bg-left search-icon" placeholder="Search" v-model="rowsFilters.search" />
					</div>	
					<div class="filter-options__items flex-x-wrap flex-auto">
						<!-- <DatePicker v-model="rowsFilters.period_start"></DatePicker>
						<DatePicker v-model="rowsFilters.period_end"></DatePicker> -->
						<!-- <DropdownAdvanced placeholder="Grants" :options="grants" v-model="rowsFilters.grants" style="max-width: 250px;" /> -->
						<DropdownAdvanced placeholder="Role" :options="roles" v-model="rowsFilters.role" style="max-width: 250px;" />
					</div>
				</template>
				<template v-slot:row="slotData">
					<td>
						<label class="module__check">
							<input type="checkbox" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
							<span class="check"></span>
							<span class="text table-user flex items-center">
								<!-- <img src="@/assets/img/profile-photo.png"> -->
								<img src="@/assets/img/user.png">
								{{ !slotData.r.fullname && slotData.r.id === userData.id ? authData.attributes['custom:full_name'] : slotData.r.fullname }}
							</span>
						</label>
					</td>
					<td>
						{{ !slotData.r.email && slotData.r.id === userData.id ? authData.attributes['email'] : slotData.r.email }}
					</td>
					<td>
						{{ !slotData.r.role && slotData.r.id === userData.id ? 'Team Owner' : slotData.r.role || '-' }}
					</td>
					<!-- <td>
						{{ !slotData.r.grants && slotData.r.id === userData.id ? 'all' : slotData.r.grants || '-' }}
					</td> -->
					<td>
						{{ !slotData.r.status && slotData.r.id === userData.id ? 'Approved' : slotData.r.status || '-' }}
					</td>
					<td class="position-stiky">
						<div class="table-options-drop table-actions dropdown-wrapper" v-if="slotData.r.id !== userData.id && slotData.r.role !== 'Team Owner'">
							<router-link :to="'/teams/edit/'+slotData.r.id" class="btn btn-icon">
								<inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
							</router-link>
							<button 
								type="button" 
								class="btn btn-icon red-color" 
								@click="confirmDelete(slotData.r.id,'Delete User?')"
							>
								<inline-svg :src="require(`@/assets/img/trash-delete.svg`)" />
							</button>
						</div>
					</td>
				</template>
			</TableAdvanced>
		</div>
	</main>
	<Pagination v-model="filteredRows" :items="rawSource" />
</div>	
</template>

<script>
import { API } from 'aws-amplify';
import {
	updateUser,
	updateCompany
} from '@/graphql/mutations';

import {
	getUser,
	getCompany,
	listUsers
} from '@/graphql/queries';

import {
	onUpdateUser,
	onDeleteUser,
} from '@/graphql/subscriptions';

import Buttons from '@/components/buttons';
import Base from '@/components/base';
import { mixTables,mixDeleteItem } from "@/mixins";
import {roles,grants,userStatus} from "@/config/teams.js"

export default {
	name: 'Teams',
	mixins:[mixTables,mixDeleteItem],
	components: {
		...Buttons,
		...Base
	},
	data() {
        return {
            source: "products",
            columns: [
                { name: "Name", filter: "fullname" },
                { name: "Email", filter: "email" },
                { name: "Role", filter: "role" },
                // { name: "Grants", filter: "grants" },
				{ name: "Status", filter: "status" },
                // { name: "Time Period", filter: "timePeriod" },
                // { name: "IOT Devices", filter: "serial_number" },
                { name: "Actions"},
            ],
            searchField:"fullname",
            //config delete item
            deleteQueryType:"deleteUser",
            calculatedFields:[
				{
					filter: 'timePeriod',
					path: [],
					calcFunction: (user)=>user.period_start&&user.period_end?user.period_start+" - "+user.period_end :"none"
				},
			], 
			users:null,
			loading: false,
        };
    },
	computed:{
		sourceData(){
			return this.users || []
		},
		companyData(){
			return this.$store.state.companyData||{}
		},
		grants(){
			return Object.entries(grants).map(([key,value])=>{
				return {id:key,name:value}
			})
		},
		roles(){
			return Object.entries(roles).map(([key,value])=>{
				return {id:key,name:value}
			})
		},
		maxTeamMembers(){
			return this.$store.state.userData.max_team_members ? this.$store.state.userData.max_team_members : 3;
		},
		userData() {
			return this.$store.state.userData || {}
		},
        authData() {
			return this.$store.state.authData || {}
		},
	},
	methods:{
		async getUsers(){
			this.loading = true;

			let company = await API.graphql({
				query: getCompany,
				variables: {
					id: this.$store.state.companyData.id,
				},
			}).then((res) => res.data.getCompany);

			
			let teamsIds = [this.userData.id];
			if(company && company.team){
				console.log('company.team:', company);
				teamsIds = [...teamsIds, ...company.team.filter(item => item !== this.userData.id)];

				if(!teamsIds.includes(company.user.id)){
					teamsIds = [company.user.id, ...teamsIds];
				}
			}

			let requests = teamsIds.map(async userID=>{
				return await API.graphql({
					query: getUser,
					variables: {
						id: userID,
					},
				}).then((res) => res.data.getUser);
			})
			await Promise.all(requests).then(res=> this.users = res);

			if(this.users.find(item => item.id === company.user.id)){
				this.users.find(item => item.id === company.user.id).role = 'Team Owner';
				this.users.find(item => item.id === company.user.id).grants = 'all';
				this.users.find(item => item.id === company.user.id).status = 'Approved';
			}


			if((this.users === null || !this.users.length) && this.userData.teamID){
				let company2 = await API.graphql({
					query: getCompany,
					variables: {
						id: this.userData.teamID,
					},
				}).then((res) => res.data.getCompany);
				console.log('company2:', company2);

				if(company2 && company2.team){
					let teamsIds2 = [...company2.team];

					if(!teamsIds2.includes(company2.user.id)){
						teamsIds2.push(company2.user.id);
					}

					let requests = teamsIds2.map(async userID=>{
						return await API.graphql({
							query: getUser,
							variables: {
								id: userID,
							},
						}).then((res) => res.data.getUser);
					})
					let usersResult = [];
					await Promise.all(requests).then(res=> usersResult = res);

					this.users = usersResult;

					console.log('this.users:', this.users);
					if(!this.users.find(item => item.id === company2.user.id).role){
						this.users.find(item => item.id === company2.user.id).role = 'Team Owner';
						this.users.find(item => item.id === company2.user.id).grants = 'all';
						this.users.find(item => item.id === company2.user.id).status = 'Approved';
					}
				}
			}

			this.loading = false;
		},
		async additionalDeleteActions(userID){
			let company = await API.graphql({
				query: getCompany,
				variables: {
					id: this.companyData.id,
				},
			}).then((res) => res.data.getCompany); 

			if(!company.team){
				return
			}
			let userTeamIndex=company.team.findIndex(u=>u==userID)
			if(userTeamIndex!==-1){
				company.team.splice(userTeamIndex,1)
			}
			const newCompany = await API.graphql({
				query: updateCompany,
				variables: {
					input: {
						id: this.companyData.id,
						team:company.team
					},
				},
			}).then((res) => res.data.updateCompany);
			await this.getUsers()
		},
		subscribe() {
			let self = this;
			API.graphql({ query: onUpdateUser })
				.subscribe({
					next: async (eventData) => {
						let user = eventData.value.data.onUpdateUser;
						if(this.users.map(item => item.id).includes(user.id)){
							await self.getUsers();
						}

					}
				});
			API.graphql({ query: onDeleteUser })
				.subscribe({
					next: async (eventData) => {
						let user = eventData.value.data.onDeleteUser;
						if(this.users.map(item => item.id).includes(user.id)){
							await self.getUsers();
						}

					}
				});
		},
	},
	async created(){
		await this.getUsers();
		this.subscribe();
	},
}
</script>

<style>

</style>